import ContractVariationApiCommon from '@jetCommon/api/contract-variation.js';

export default class ContractVariationsApi extends ContractVariationApiCommon {
    start(data) {
        return this.apiPost('start/', data);
    }

    step(id, scope, data) {
        return this.apiPatch(`${id}/step/?scope=${scope}`, data);
    }

    firstValidVariationDate(companyId) {
        return this.apiGet(`first_valid_variation_date/?company_id=${companyId}`);
    }
}
